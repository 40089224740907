<template>
  <div id="profile-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <!--<p>Todo:</p>
    <ul>
      <li>Further categorize Items</li>
      <li>Make more Content</li>
      <li>Connect final Items to content and tools</li>
      <li>Generate i18n</li>
    </ul>-->

    <div class="card">
      <h3 class="card-header">
        Deine Daten
      </h3>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3 border-right">
            <button :class="view === 'base' ? 'btn-light' : 'btn-link'" class="btn btn-block" @click="view = 'base'">Stammdaten</button>
            <button :class="view === 'address' ? 'btn-light' : 'btn-link'" class="btn btn-block" @click="view = 'address'">Adresse</button>
            <button :class="view === 'login' ? 'btn-light' : 'btn-link'" class="btn btn-block" @click="view = 'login'">Login</button>
            <button :class="view === 'settings' ? 'btn-light' : 'btn-link'" class="btn btn-block" @click="view = 'settings'">Einstellungen</button>
          </div>
          <div class="col-sm-9">
            <div v-if="view === 'base'">
              <h4>Stammdaten</h4>
              <div class="form-row">
                <div class="col-sm-7">
                  <div class="form-group">
                    <label for="firstName">Vorname</label>
                    <input id="firstName" v-model="newUser.firstName" class="form-control" placeholder="Vorname" type="text">
                  </div>
                  <div class="form-group">
                    <label for="lastName">Nachname</label>
                    <input id="lastName" v-model="newUser.lastName" class="form-control" placeholder="Nachname" type="text">
                  </div>
                  <div class="form-group">
                    <label for="nickName">Dein Nickname für Kollabos</label>
                    <input id="nickName" v-model="newUser.nickname" class="form-control" placeholder="Nickname" type="text">
                  </div>
                  <div class="form-group">
                    <label for="color">Deine Lieblingsfarbe für Kollabos</label>
                    <input id="color" v-model="newUser.settings.chatColor" class="form-control" placeholder="Farbe ausählen" type="color">
                  </div>
                  <div class="form-group">
                    <label for="telephone">Telefon</label>
                    <input id="telephone" v-model="newUser.telephone" class="form-control" placeholder="Telefon" type="text">
                  </div>
                  <hr>
                  <div class="form-group">
                    <label>Fortgeschrittener Modus?</label>
                    <div class="btn-group btn-block">
                      <button @click="newUser.settings.advanced = false" class="btn" :class="newUser.settings.advanced ? 'btn-light' : 'btn-secondary'">
                        Aus
                      </button>
                      <button @click="newUser.settings.advanced = true" class="btn ml-1" :class="newUser.settings.advanced ? 'btn-primary' : 'btn-light'">
                        Ein
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5 text-center">
                  <div class="avatar-wrapper">
                    <img v-if="newUser.logo" :src="newUser.logo" alt="Profilbild" class="img-fluid avatar">
                    <div v-else class="avatar-placeholder">
                  <span>
                    <i class="fas fa-arrow-up"></i><br>
                    Profilbild hochladen
                  </span>
                    </div>
                  </div>
                  <label>Profilbild</label>
                  <input id="avatar-url" v-model="newUser.logo" class="form-control mb-3" placeholder="URL einfügen" type="text">
                  <p class="text-center small text-muted">oder:</p>
                  <FileUpload @filesSaved="filesSaved($event)" :multiple="false"></FileUpload>
                </div>
              </div>
            </div>
            <div v-if="view === 'address'">
              <h4>Adresse</h4>
              <div class="form-group">
                <label for="address.name">Name</label>
                <input id="address.name" v-model="newUser.address.name" class="form-control" placeholder="Name" type="text">
              </div>
              <div class="form-group">
                <label for="address.street">Straße und Hausnummer</label>
                <input id="address.street" v-model="newUser.address.street" class="form-control" placeholder="Straße und Hausnummer" type="text">
              </div>
              <div class="form-row">
                <div class="col-sm-3">
                  <div class="form-group">
                    <label for="address.zipCode">PLZ</label>
                    <input id="address.zipCode" v-model="newUser.address.zipCode" class="form-control" placeholder="PLZ" type="text">
                  </div>
                </div>
                <div class="col-sm-9">
                  <div class="form-group">
                    <label for="address.city">Stadt</label>
                    <input id="address.city" v-model="newUser.address.city" class="form-control" placeholder="Stadt" type="text">
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="address.area">Region</label>
                    <input id="address.area" v-model="newUser.address.region" class="form-control" placeholder="Region" type="text">
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="address.country">Land</label>
                    <input id="address.country" v-model="newUser.address.country" class="form-control" placeholder="Land" type="text">
                  </div>
                </div>
              </div>
            </div>
            <div v-if="view === 'login'">
              <h4>Login</h4>
              <div class="form-group">
                <label for="email">E-Mail</label>
                <input id="email" v-model="newUser.email" disabled="disabled" class="form-control" placeholder="E-Mail Adresse" type="email">
              </div>
              <div class="form-group">
                <label for="password">Passwort ändern</label>
                <input id="password" v-model="newUser.password" class="form-control" placeholder="Passwort" type="password" autocomplete="new-password">
              </div>
              <div class="form-group">
                <label for="password-repeat">Passwort wiederholen</label>
                <input id="password-repeat" class="form-control" placeholder="Passwort wiederholen" type="password"
                       autocomplete="none"
                       @change="checkPassword($event)">
              </div>

              <div v-if="failMsg" class="row align-items-center my-4">
                <div class="col-auto text-center p-0">
                  <i class="fad fa-times-circle fa-2x text-danger ml-4"></i>
                </div>
                <div class="col">
                  <p class="text-left mb-1">
                    <strong>Sorry.</strong><br>
                    {{ failMsg }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="view === 'settings'">
              <h4>Einstellungen</h4>
              <p>
                Hier gibt es noch nichts zu sehen...
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row justify-space-between align-items-center">
          <div class="col-auto mr-0">
            <button class="btn btn-secondary mr-3" @click="dumpData($event)">Datensatz rauslassen</button>
            <button class="btn btn-primary mr-3" @click="updateProfile">Profil updaten</button>
          </div>
          <div class="col"></div>
          <div class="col-auto">
            <p class="small muted mb-0">
              Mitglied seit: <br>
              {{ newUser.createdAt.toLocaleLowerCase().slice(0, 10) }}
            </p>
          </div>
          <a id="dataset-download" style="display:none"></a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import FileUpload from "@/components/FileUpload";
export default {
  name: 'Profile',
  components: {FileUpload},
  data() {
    return {
      view: 'base',
      newUser: {},
      failMsg: ""
    }
  },
  computed: {},
  methods: {
    checkPassword($event) {
      if ($event.target.value !== this.newUser.password) {
        this.failMsg = "Die Passwörter stimmen nicht überein.";
      } else {
        this.failMsg = "";
      }
    },
    filesSaved($event) {
      let files = JSON.parse(JSON.stringify($event));
      this.$store.dispatch('upload/uploadFiles', { files, path: "profile-img/" + this.user._id })
          .then(res => {
            console.debug(res);
            this.newUser.logo = res.files[0];
            this.$store.dispatch('user/updateUser', this.newUser)
          });
    },
    updateProfile() {
      if (this.failMsg) {
        alert("Deine Passwörter stimmen nicht überein. Lösche das Feld unter \"Login-Daten\" oder versuche es erneut.");
      } else {
        if (!this.user.activeProjectId) {
          this.user.activeProjectId = this.project.id;
        }
        this.$store.dispatch('user/updateUser', this.newUser)
            .then(() => {
              this.$store.dispatch("auth/init");
            });
      }
    },
    dumpData($event) {
      $event.stopPropagation();

      let data = {
        user: this.$store.getters.getUser,
        project: this.$store.getters.getProject,
        scrape: {
          netNodes: this.project.netNodes,
          netLinks: this.project.netLinks,
          contentNodes: this.project.contentNodes,
          techNodes: this.project.techNodes,
        }
      }

      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
      let dlAnchorElem = document.getElementById('dataset-download');
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "web-strategy-toolbox-dataset.json");
      dlAnchorElem.click();
    }
  },
  beforeMount() {
    this.newUser = this.user;
    delete this.newUser.password;
  }
}
</script>

<style lang="scss" scoped>
.avatar-wrapper {
  width: 66%;
  height: 0;
  padding-top: 66%;
  overflow: hidden;
  border-radius: 100%;
  text-align: center;
  position: relative;
  margin: 1rem auto;

  .avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.avatar-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--light);

  span {
    position: absolute;
    font-weight: 500;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
</style>
